<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <img src="../../assets/images/logo/logo_dark.png" class="img-fluid" />
      </div>
      <div class="card">
        <div class="card-body">
          <notification-container />
          <BaseAlert v-if="error" :message="error" type="danger" />
          <form class="form" @submit.prevent="handleLoginForm">
            <BaseInput
              label="Adresse e-mail"
              type="email"
              placeholder="Adresse email"
              v-model="credential.email"
            />
            <BaseInputPassword
              label="Mot de passe"
              placeholder="Mot de passe"
              v-model="credential.password"
            />
            <div class="form-group text-left">
              <div class="checkbox checkbox-sec checkbox-primary d-inline">
                <input
                  type="checkbox"
                  id="checkbox-remember"
                  v-model="credential.remember"
                />
                <label for="checkbox-remember" class="cr">
                  Se souvenir de moi</label
                >
              </div>
            </div>
            <div class="form-group text-center">
              <BaseButton
                button-class="btn-block mt-3 btn-secondary font-weight-bold"
                :disabled="disabledSubmitBtn"
                :loading="loading"
                :showLoading="true"
              >
                Connexion
              </BaseButton>
              <hr />
              <div class="text-center">
                <p class="mb-0 text-muted">
                  <router-link :to="{ name: 'PasswordReset' }"
                    >Mot de passe oublié ?
                  </router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { required, email } from "vuelidate/lib/validators";
import { recaptchaMixin } from "../../mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";

export default {
  name: "Login",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer },

  data() {
    return {
      loading: false,
      credential: {
        email: null,
        password: null,
        remember: false,
        recaptcha: null,
        attempt: 3,
        web: true
      },
      error: null
    };
  },

  computed: {
    disabledSubmitBtn() {
      return this.$v.$invalid || this.loading || this.recaptchaToken === null;
    }
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`)
    };
  },

  mounted() {
    if (this.$route.query.email)
      this.credential.email = decodeURI(this.$route.query.email);
  },

  methods: {
    handleLoginForm() {
      this.$v.$touch();
      if (!this.$v.invalid) {
        this.error = null;
        this.loading = true;
        if (this.credential.attempt)
          this.credential.recaptcha = this.recaptchaToken;
        this.$store
          .dispatch("auth/login", this.credential)
          .then(() => {
            this.$router.push(
              this.$route.query.redirect || { name: "dashboard" }
            );
          })
          .catch(err => {
            this.loadRecaptcha();
            if (err) {
              if (err.msg) this.error = err.msg;
              else this.error = err.response.data.message;
            }
            this.loading = false;
          });
      }
    }
  },

  validations: {
    credential: {
      email: { required, email },
      password: { required }
    }
  }
};
</script>

<style scoped></style>
